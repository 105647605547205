import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'antd/dist/antd.css'
import './App.css'

// console.log = () => {}
// console.warn = () => {}

ReactDOM.render(<App />, document.getElementById('root'))
