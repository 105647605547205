export const THEME = {
	COLORS: {
		WHITE: '#ffffff',
		BLACK: '#000000',
		ERROR_RED: '#f00006',
		RED: '#F41F2C',
		RED_2: '#f14d51',
		FADE_RED: '#fccfd2',
		TEXT_RED: '#f14d51',
		BLUE: '#1890FF',
		TEXT_BLUE: '#00699F',
		TEXT_BLACK: '#666666',
		TEXT_BLACK2: '#848484',
		GRAY: '#585858',
		GRAY2: '#939393',
		GRAY3: '#E7E7E7',
		GRAY4: '#BDBDBD',
		GREEN: '#3CB371',
		GREEN2: '#81A854',
		GREEN3: '#EAF1E4',
		GREEN4: '#7CA94A',
		ORANGE: '#E68A3D',
		TEXT_YELLOW: '#ffcc00',
	},
}
