import styled from 'styled-components'
import { THEME } from '../../themes'

export const HeaderComponent = styled.div`
	background-color: ${THEME.COLORS.RED};
	width: 100%;
	height: 6em;
	display: flex;
	justify-content: center;
	align-items: center;
`
