export const IMAGES = {
	'info.png': require('./background/info.png'),
	'auto.png': require('./icons/auto.png'),
	'compulsory.png': require('./icons/compulsory.png'),
	'work-waiting.png': require('./icons/work-waiting.png'),
	'tax.png': require('./icons/tax.png'),
	'info-customer.png': require('./icons/info-customer.png'),
	'success.png': require('./icons/success.png'),
	'wait_pay.png': require('./icons/wait_pay.png'),
	'commission-standart.png': require('./commission/commission-standart.png'),
	'commission-debit.png': require('./commission/commission-debit.png'),
	'commission-quick.png': require('./commission/commission-quick.png'),
	'info_1.png': require('./background/info_1.png'),
	'info_2.png': require('./background/info_2.png'),
	'bg-payment.png': require('./background/bg-payment.png'),
	'bg-thaiqr.png': require('./background/bg-thaiqr.png'),
}
