export const LOCAL_STORAGE = {
	USER_AUTH: 'USER_AUTH',
	COVID_PLAN: 'COVID_PLAN',
	PERSONAL_INFORMATION: 'PERSONAL_INFORMATION',
	CUSTOMER: 'CUSTOMER',
	PURCHASE: 'PURCHASE',
	QUOTATION: 'QUOTATION',
	PDF: 'PDF',
	CREDIT_PAY: 'CREDIT_PAY',
	INVOICE: 'INVOICE',
}
