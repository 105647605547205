import React from "react";
import { CloseOutlined } from "@ant-design/icons";

const Icons = () => {
  return;
};
const IconCloseOutlined = ({ style }) => {
  return <CloseOutlined style={style} />;
};

Icons.CloseOutlined = IconCloseOutlined
export { Icons };
