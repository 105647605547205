export const ACTION_TYPES = {
	SET_USER: 'SET_USER',
	SET_ROUTE_HISTORY: 'SET_ROUTE_HISTORY',
	SET_COVID_PLAN_SELECTED: 'SET_COVID_PLAN_SELECTED',
	IS_LOADING: 'IS_LOADING',
	CUSTOMER: 'CUSTOMER',
	ORDER: 'ORDER',
	PURCHASE: 'PURCHASE',
	QUOTATION: 'QUOTATION',
	PDF: 'PDF',
	CREDIT_PAY: 'CREDIT_PAY',
	CREDIT: 'CREDIT',
	INVOICE: 'INVOICE',
  PROFILE: 'PROFILE',
  SET_PREMISSIONS: 'SET_PREMISSIONS',
  SET_NOTI_OVERDUE: 'SET_NOTI_OVERDUE',
  SET_FILES_SUCCESS: 'SET_FILES_SUCCESS',
  CREDIT_LIST: 'CREDIT_LIST',
  SET_DETAIL_SLIP_BILL: 'SET_DETAIL_SLIP_BILL',
  INSTALLMENT_LIST: 'INSTALLMENT_LIST',
  POPUP: 'POPUP_LIST',
}
